.MuiCardContent-root {
    padding: 0 !important;
}

.MuiCardContent-root:last-child {
    padding-bottom: 24px !important;
}

.MuiList-padding {
    padding-bottom: 0 !important;
}

.MuiListItem-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.MuiListItemIcon-root {
    min-width: 40px !important;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
  /* Styles */
    .MuiAppBar-root {
        min-height: 70px;
    }
    .MuiAppBar-root button {
        height: 100%;
    }
}